import React from "react";

// Customizable Area Start
import {
  Modal, Typography, Box
} from "@material-ui/core";
import { RemoveCircleOutline, AddCircleOutline, FiberManualRecord, ArrowForward } from "@material-ui/icons"
import { Calendar } from "react-multi-date-picker"
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { CustomButton } from "../../../components/src/MultiSelectCalender";
import { BootstrapButton } from "../../user-profile-basic/src/UserProfileCustomer.web";
import { close } from "../../email-account-registration/src/assets";
import { primaryColor } from "../../../components/src/theme";
import { withStyles, Theme } from "@material-ui/core/styles";
// Customizable Area End

interface Props {
  id: string;
  navigation: any;
  // Customizable Area Start
  instance: any;
  classes?:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

class AvailabilityModal extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { instance, classes } = this.props;
    const { seats = 4, tripDetails: {
      title = '', description = '',
      group_preference: {
        data: {
          attributes: {
            preference_type = '', min_seat = 0, max_seat = 0
          } = {} } = {}
      } = {},
    } } = instance.state;
/* istanbul ignore next */
    const dateArray = instance.state.trip_schedules.map((data: any) => {
      return data.date.replace(/-/g, '/');
    })

/* istanbul ignore next */

    const dateFormates = (dateString: any) => {
      if (!dateString) return null;
      const parts = dateString.split('-');
      const month = parseInt(parts[1], 10);
      const day = parseInt(parts[2], 10);
      const year = parts[0];
      const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
      ];
      const monthName = monthNames[month - 1];
      return `${monthName} ${day}, ${year}`;
    }


    const availbleNoOfPeople = (title: string, people: number) => {
      return <Typography component={'div'} style={{ marginBottom: '-12px' }}>
        <Typography component={'span'} className={classes.title}>
          {title}
        </Typography>
        <Typography component={'span'} className={classes.greenFont}>
          {people}
        </Typography>
      </Typography>
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <Modal
        open={instance.state.isOpen}
        onClose={instance.handleClose}
        data-test-id="avmodal"
      >
        <Box className={classes.availabilityModal}>
          <span className={classes.closeBtn} onClick={instance.handleClose}>
            <img src={close} alt="close" style={{ margin: '0 -24px 16px 0', cursor: 'pointer' }} />
          </span>
          <Typography className={classes.heading} gutterBottom>
            {title}
          </Typography>
          <Typography className={classes.description}>
            {description}
          </Typography>
          <Box className={classes.flexStyle}>
            <Box>
             <Calendar
                data-test-id="calander_pop"
                value={instance.state.selectedDate.date}
                onChange={instance.handleDateChange}
                monthYearSeparator=" "
                highlightToday={false}
                multiple={false}
                mapDays={({ date }) => {
                  return {
                    style: {
                      color: dateArray.includes(date.toString()) ? primaryColor : "#000",
                    },
                  };
                }}
                renderButton={<CustomButton />}
              />
              <Typography style={{ marginTop: '32px', display: 'flex', gap: '12px', alignItems: 'center' }} className={classes.availableDate}>
                <FiberManualRecord style={{ color: '#FE7F2D', width: '12px', height: '12px' }} />  Available dates
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection={'column'}
              justifyContent={'space-between'}
              width={'100%'} height={'36vh'}
            >
              <Box className={classes.flexStyleColumn}>
                <Typography className={classes.noOfPeople}>
                  Number of people
                </Typography>

                <Box style={{
                  width: '132px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                  <RemoveCircleOutline
                    onClick={() => instance.handleDecrement(preference_type, min_seat)}
                    data-test-id='decrement-button'
                    className={classes.icon} />
                  <Typography style={{
                    color: instance.state.noOfPeople === 0
                      ? 'rgba(33, 36, 38, 0.80)' : '#FE7F2D'
                  }}>
                    {instance.state.noOfPeople}
                  </Typography>
                  <AddCircleOutline onClick={() => instance.handleIncrement(preference_type, seats, max_seat)}
                    data-test-id='increment-button'
                    className={classes.icon} />
                </Box>
                {preference_type == 'public'
                  ? availbleNoOfPeople('Available seats :', seats)
                  : <>
                    {availbleNoOfPeople('Minimum number of people :', min_seat)}
                    {availbleNoOfPeople('Maximum number of people :', max_seat)}
                  </>
                }
                { 
                /* istanbul ignore next */
                instance?.state?.isEdit &&
                  <>
                  {
                    instance.state?.selectedDate?.date !=  instance.state?.bookingDate &&<Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', gridGap: '10px' }}>
                      <Typography variant="body1" align="left">Updating trip date</Typography>
                      <Box className={classes.datesBox}><Typography variant="body1" className={classes.dateContainers}>{dateFormates(instance.state?.bookingDate)}</Typography>
                        <Box style={{ padding: '10px 20px' }}><ArrowForward /></Box>
                        <Typography variant="body1" className={classes.dateContainers}>{dateFormates(instance.state?.selectedDate?.date)}</Typography></Box>
                    </Box>
                  }
                  
                    {instance.state?.bookednoOfPoeple != instance.state?.noOfPeople &&
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', gridGap: '10px' }}>
                      <Typography variant="body1" align="left">Updating number of people</Typography>
                      <Box className={classes.datesBox}><Typography variant="body1" className={classes.dateContainers}>{instance.state?.bookednoOfPoeple}</Typography>
                        <Box style={{ padding: '10px 20px' }}><ArrowForward /></Box>
                        <Typography variant="body1" className={classes.dateContainers}>{instance.state?.noOfPeople}</Typography></Box>
                    </Box>}
                  </>
                }

                {instance.state.seatsDateError && <Typography style={{ color: 'red' }}>{instance.state.seatsDateError}</Typography>}
              </Box>
              <Box textAlign={'end'} marginTop={'15px'}>
                
                <BootstrapButton
                  onClick={
                   /* istanbul ignore next */
                    () =>{
                    instance.postOrderRequest('apiCallIdBookingSummary', 'check_booking', instance)}}
                  type="button" disabled={instance.state.noOfPeople === 0}>
                  Check availability
                </BootstrapButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  availabilityModal: {
    width: '100%',
    maxWidth: '67.5%',
    padding: '24px 48px 48px',
    position: 'fixed' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    zIndex: 1,
    borderRadius: '12px',
    boxShadow: 'none',
    textAlign: 'center' as const,
    height: '90%'
  },
  heading: {
    color: 'rgba(33, 36, 38, 0.90)',
    fontFamily: 'Inter',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '130%',
    textAlign: 'start' as const,
    [theme.breakpoints.down(768)]:{
      fontSize: '24px'
    }
  },
  description: {
    color: 'rgba(33, 36, 38, 0.80)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    textAlign: 'start' as const,
  },
  closeBtn: {
    display: 'flex',
    justifyContent: 'end'
  },
  flexStyle: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '92px',
    marginTop: '48px'
  },
  flexStyleColumn: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column' as const,
    gap: '20px',
    width: '100%',
    height: '44vh'
  },
  noOfPeople: {
    color: 'rgba(33, 36, 38, 0.90)',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
  },
  availableSeats: {
    color: 'rgba(33, 36, 38, 0.90)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
  },
  title: {
    color: 'rgba(33, 36, 38, 0.90)',
    lineHeight: '140%',
    marginRight: '8px'
  },
  greenFont: {
    color: 'rgb(57, 171, 47, 0.90)',
    fontWeight: 600,
    lineHeight: '140%'
  },
  availableDate: {
    fontWeight: 500,
    lineHeight: '140%',
    opacity: 0.9
  },
  icon: {
    color: 'rgba(33, 36, 38, 0.80)',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    cursor: 'pointer'
  },
  datesBox: {
    display: 'flex',
    gap: '10px'
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-start'
  },
  dateContainers: {
    background: '#ECECEC',
    padding: '10px 20px',
    whiteSpace: 'nowrap' as const
  },
  [theme.breakpoints.between(768, 1024)]: {
    flexStyle: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '92px',
      marginTop: '48px',
      flexDirection: 'column'
    },
  },
  [theme.breakpoints.down(768)]: {
    flexStyle: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '92px',
      marginTop: '48px',
      flexDirection: 'column'
    },

    availabilityModal: {
      left: '44%',
      height: '60vh',
      overflowY: 'auto' as const,
      maxWidth: '86.5%',
      padding: '18px 35px 35px',
    }
  },
})

export default withStyles(styles)(AvailabilityModal);
// Customizable Area End
