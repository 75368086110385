import React from "react";
// Customizable Area Start
import {
  Button,
  InputBase,
  Box,
  Divider,
  Typography,
  Grid,
  Avatar, Tooltip,
  Chip,
  Drawer
} from "@material-ui/core";
import {
 Search
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { Rating, Pagination } from "@material-ui/lab"
import { createTheme, ThemeProvider,withStyles, Theme } from "@material-ui/core/styles";
import TripPriceFilter from "../../../components/src/TripPriceFilter.web";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import TripDateFilter from "../../../components/src/TripDateFilter.web";

import ServiceRatingFilter from "../../../components/src/ServiceRatingFilter";
import CustomCheckboxFilter, { OptionCheckboxFilter } from "../../../components/src/CustomCheckboxFilter";
import CustomRadioFilter, { OptionRadioFilter } from "../../../components/src/CustomRadioFilter";
import CountryFilter from "../../../components/src/CountryFilter";
import CityFilter from "../../../components/src/CityFilter";
import LazyImage from "../../../components/src/LazyImage";
import { capitalizeWords } from "../../../components/src/utils";
import { imgImageLoader } from "../../customform/src/assets";
import { primaryColor } from "../../../components/src/theme";
import { noTripMatched } from "../../catalogue/src/assets";
import FilterSelect from "../../../components/src/FilterSelect";



const tripTypeFilterOptions: OptionCheckboxFilter[] = [
  {
    label: "Easy",
    value: "Easy",
    noOfTrips: 300,
    selected: false,
  },
  {
    label: "Medium",
    value: "Medium",
    noOfTrips: 255,
    selected: false,
  },
  {
    label: "Hard",
    value: "Hard",
    noOfTrips: 255,
    selected: false,
  },
]
const radioFilterOptions: OptionRadioFilter[] = [
  {
    label: "Yes",
    value: "true",
    noOfTrips: 300,
  },
  {
    label: "No",
    value: "false",
    noOfTrips: 255,
  },
  {
    label: "All",
    value: "all",
    noOfTrips: 45,
  },

]

const sortByOderFilter = [
  { value: 'highToLow', label: 'Rating (high to low)' },
  { value: 'lowToHigh', label: 'Rating (low to high)' },
  { value: 'ZtoA', label: 'Price (high to low)' },
  { value: 'AtoZ', label: 'Price (low to high)' },]

  const renderFIlterMobile = (state:any,getCityListApiASRequest:(country: string) => void, handleCheckboxSelect:any, handleSingleFilterValueSelect:any, handleServiceRatingChange:any, classes:any) => {
    const {
      currencyList,
      priceFrom,
      priceTo,
      currency,
      tripType,
      country,
      countryList,
      city,
      cityList,
      travelerType,
      groupPreferenceFilterOptions,
      tripDesignerFilterOptions,
      guidedTour,
      kidsFriendly,
      femalesOnly,
      vibeList,
      periodList,
      serviceRating,
      radioGuidedTourFilterOptions,
      radioKidsFilterOptions,
      radioFemaleFilterOptions,
      countByRatingOption
    } = state;
    return (
      <Box className={classes.filtersContainerAS}>
      <Typography style={{ padding: '0 16px', fontSize: '20px', fontWeight: 500 }} variant="h4">Filters</Typography>
      <Divider />
      <TripPriceFilter
        currencyList={currencyList}
        priceFrom={priceFrom}
        priceTo={priceTo}
        currency={currency}
        onChange={handleSingleFilterValueSelect}
      />
      <Divider />
      <TripDateFilter 
      onChange={handleSingleFilterValueSelect}
      
      />
      <Divider />
      <div style={{ padding: '0px 16px' }}>
        <ServiceRatingFilter
        serviceRatingChange={handleServiceRatingChange}
        selectedValue={serviceRating}
        countByRatingOption={countByRatingOption}
        />
      </div>
      <Divider />
      <CustomCheckboxFilter
        title="What are you up to?"
        options={tripType}
        stateName="tripType"
        handleCheckboxChange={handleCheckboxSelect}
      />
      <Divider />
      <CountryFilter
        country={country}
        countries={countryList}
        handleCountryChange={handleSingleFilterValueSelect}
        getCityListRequest={getCityListApiASRequest}
      />
      <Divider />
      <CityFilter
        city={city}
        cities={cityList}
        handleCityChange={handleSingleFilterValueSelect}
      />
      <Divider />
      <CustomCheckboxFilter
        title="Traveler Type"
        options={travelerType}
        stateName="travelerType"
        handleCheckboxChange={handleCheckboxSelect}
      />
      <Divider />
      <CustomCheckboxFilter
        title="Group Preference"
        options={groupPreferenceFilterOptions}
        stateName="groupPreferenceFilterOptions"
        handleCheckboxChange={handleCheckboxSelect}
      />
      <Divider />
      {/* <CustomCheckboxFilter
        title="Trip Type"
        options={tripTypeFilterOptions}
        stateName="tripTypeFilterOptions"
        handleCheckboxChange={this.handleCheckboxSelect}
      /> */}
      <Divider />
      <CustomCheckboxFilter
        title="Trip Designer"
        options={tripDesignerFilterOptions}
        stateName="tripDesignerFilterOptions"
        handleCheckboxChange={handleCheckboxSelect}
      />
      <Divider />
      <CustomRadioFilter
        selectedValue={guidedTour}
        title="Guided Tour"
        options={radioGuidedTourFilterOptions}
        handleRadioChange={handleSingleFilterValueSelect}
        stateName="guidedTour"
      />
      <Divider />
      <CustomRadioFilter
        selectedValue={kidsFriendly}
        title="Kids Friendly"
        options={radioKidsFilterOptions}
        handleRadioChange={handleSingleFilterValueSelect}
        stateName="kidsFriendly"
      />
      <Divider />
      <CustomRadioFilter
        selectedValue={femalesOnly}
        title="Females Only"
        options={radioFemaleFilterOptions}
        handleRadioChange={handleSingleFilterValueSelect}
        stateName="femalesOnly"
      />
      <Divider />
      <CustomCheckboxFilter
        title="Vibes"
        stateName="vibeList"
        options={vibeList}
        handleCheckboxChange={handleCheckboxSelect}
      />
      <Divider />
      <CustomCheckboxFilter
        title="Time"
        options={periodList}
        stateName="periodList"
        handleCheckboxChange={handleCheckboxSelect}
      />
  
    </Box>
  
    )
  }
const TripCard = (classes: any, list: Record<string, any>[], title: string, navigation: any) => {

  const tripList = [...list].sort((a, b) => b.attributes.rating - a.attributes.rating)

  return <Grid className={classes.tripContainer} container spacing={5}>

    { 
    tripList.map((trip, index) => {
      const { title, provider_type,
        price, discounted_price, currency_code, city, country, primary_image_url,
        average_rating, account: { data: {attributes: {agency_logo: agent_img, first_name, last_name}} }
      } = trip.attributes

      return (
        <Grid item md={4} key={trip.id}>
          <div data-test-id="checkid" key={trip.id} className={classes.innerTripSlideBlockAS}>
            <div data-test-id="navigateid1" onClick={() => navigation.navigate('TripDetails', { id: trip.id })} className={classes.imageVignetteAS}>
              <LazyImage src={primary_image_url} alt="Image" className={`${classes.imageAS} ${classes.fixedImgAS} `} />
              <Box className={classes.agencyDetailsAS}>
                <Avatar alt="AgencyLogo" src={agent_img} className={classes.badgeAS} />
                <Typography className={classes.font14AS} style={{
                  color: '#FFF',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '150%',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%'
                }} component={'pre'}>
                  <Tooltip title={`${first_name+ ' ' + last_name} (${capitalizeWords(provider_type)})`} arrow>
                    <Button classes={{ label: classes.serviceNameAS }} style={{ padding: 0 }}>
                      {first_name+ ' ' + last_name}
                    </Button>
                  </Tooltip> ({capitalizeWords(provider_type)})
                </Typography>
                <Chip
                  label={
                    <>
                      {Number.isInteger(average_rating) ? average_rating : average_rating?.toFixed(1)}
                      <Rating value={average_rating * 0.2} precision={0.1}
                        readOnly size="medium" max={1}
                        style={{ color: "#FE7F2D", margin: 'auto 0' }}
                        classes={{
                          icon: classes.ratingIconAS
                        }}
                      />
                    </>
                  }
                  classes={{ label: classes.chipLabelAS }}
                  className={classes.chipAS}
                />
              </Box>
            </div>
            <Typography data-test-id="navigateid2" onClick={() => navigation.navigate('TripDetails', { id: trip.id })} className={classes.tripNameAS}>
              {title}
            </Typography>
            <Typography className={classes.tripLocationAS}>
              {country}, {city}
            </Typography>
            <Typography className={classes.priceAS}>
              {discounted_price
                ? <>{discounted_price} {currency_code} <s className={classes.strikeThroughAS}>{price} {currency_code}</s></>
                : <>{price} {currency_code}</>
              }
            </Typography>
          </div>
        </Grid>
      )
    })}
  </Grid >
}



// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      countryId,
      cityId,
      tripList,
      loader,
      pagination,
      searchString,
      isMobile
    } = this.state;

console.log({countryId, cityId})
  
    const SearchBar = () => (
      <div className={`${classes.searchAS}`}>
        <InputBase
          data-test-id="searchInput"
          value={searchString}
          onChange={(e) => this.handleSearchValue(e.target.value)}
          onKeyDown={(e) => this.handleEnterPressSearch(e)}
          classes={{
            root: classes.inputRootAS,
            input: classes.inputInput,
          }}
          inputProps={{ className: classes.searchBarAS }}
        />
        <Button 
          data-test-id="searchBtn"
        onClick={() => this.getAdvancedSearchTripListAPIRequest()} className={classes.searchIconAS}>
          <Search /> <span className={classes.searchkeyWord}> Search </span>
        </Button>
      </div>
    )

    return (
      <ThemeProvider theme={theme}>
        <Box maxWidth="lg" className={classes.outrAdvncSrch}>
          {/* Customizable Area Start */}
          <Box  className= {classes.breadcrSearchcont}>
            <Box className= {classes.searchbreadCOnt}>
            <Breadcrumbs list={[
              {
                title: 'Home',
                link: '/LandingPage'
              },
              {
                title: "Search results",
              },
            ]} />
            <Box className={classes.resetfiltercontainer}>
          <div >
              <Typography  className = {classes.resetfltrtext} data-testid= 'resetFilter'
              onClick={() => this.handleResetFilters()}>
                Reset Filters
              </Typography>
            </div>
            <span onClick= {() => this.handleMenuCLick(true)} data-testid='menuiconBtn'>
            < MenuIcon className={classes.menuIcon}/>
            </span>

            </Box>
            </Box>
            <div className={classes.filterInput}>
                <FilterSelect
                  advancedSearch={true}
                  field="Sort by"
                  options={sortByOderFilter}
                  filterName="sortBy"
                  handleFilterChange={this.handleSorting}
                />
              </div>
            {/* <SearchBar /> */}
          {SearchBar()}
          </Box>
          <Divider style={{ margin: '48px 0' }} />
          {
            searchString ?
            <Typography>Search Results for &nbsp;<span style={{ fontWeight: "bold", fontSize: 20 }}>{`"${this.state.searchString}"`}</span></Typography>
            : null
          }
          <div className={classes.dropdownContainer}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '32px 0', width: '100%' }}>
              <Typography style={{color: primaryColor, textDecoration: 'underline', cursor: 'pointer'}}
              onClick={() => this.handleResetFilters()}
              data-testid= 'resetFilter2'>Reset Filters</Typography>
              <div style={{ marginLeft: 'auto' }}>
                <FilterSelect
                  advancedSearch={true}
                  field="Sort by"
                  options={sortByOderFilter}
                  filterName="sortBy"
                  handleFilterChange={this.handleSorting}
                />
              </div>
            </div>
          </div>
          <Grid container spacing={3} className= {classes.mainContainerTripAndFilter}>
          { isMobile ? 
           <Grid item md={3} className= { this.state?.isMenuClicked ? classes.filtercontainervisible : classes.filtercontainer } data-testid = 'drawerContianer'>
            <Drawer open={this.state?.isMenuClicked} onClose = { () => this.handleMenuCLick(false)}  data-testid = 'drawer'>
              {renderFIlterMobile(this.state, this.getCityListApiASRequest, this.handleCheckboxSelect, this.handleSingleFilterValueSelect, this.handleServiceRatingChange, classes)}
            </Drawer>
            </Grid> 
            :
            <Grid item md={3} className= { this.state?.isMenuClicked ? classes.filtercontainervisible : classes.filtercontainer }>
              {renderFIlterMobile(this.state, this.getCityListApiASRequest, this.handleCheckboxSelect, this.handleSingleFilterValueSelect, this.handleServiceRatingChange, classes)}
            </Grid> 
            }

            <Grid item md={9} className={classes.OuterContainerTrip}>
              <Grid container justifyContent="center" alignItems="center"  className= {classes.loader}>
              {loader && <img src={imgImageLoader} width={'180px'} />}
              </Grid>
              {!loader && tripList.length === 0 && <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'} gridRowGap={'42px'}
                  flexDirection={'column'} marginBottom={'168px'} marginTop={'160px'}>
                  <img src={noTripMatched} alt='No Bookings' />
                  <Typography className={classes.noBookingTextAS}>
                    {"No trips are matching your search criteria"}
                  </Typography>
                </Box>
              </Grid>} {/* Show image only if tripList is empty */}
              {!loader && tripList.length > 0 && TripCard(classes, tripList, 'Culture', this.props.navigation)} {/* Show tripList only if it's not empty and loader is hidden */}

            </Grid>
            <Grid container spacing={5}>
              <Grid item md={3}>
                {""}
              </Grid>
              <Grid container item md={9} justifyContent="center" alignItems="center">
                {pagination.total_pages > 1 &&
                  <Box position={"relative"}>
                    <Box marginY={5}>
                      <Pagination
                        count={pagination.total_pages}
                        page={Number(pagination.current_page)}
                        onChange={this.handlePagination}
                        // defaultPage={1}
                        classes={{
                          root: classes.paginationRootAS,
                          ul: classes.paginationItemAS
                        }}
                      />
                    </Box>
                  </Box>
                }
              </Grid>
            </Grid>
          </Grid>
          
          {/* Customizable End Start */}
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const advancedSearchStyles = (theme: Theme) => ({
  searchAS: {
    borderRadius: '53px',
    border: '0.5px solid rgba(33, 36, 38, 0.50)',
    background: '#FFF',
    maxWidth: '700px',
    height: '64px',
    position: 'relative' as 'relative',
    backgroundColor: '#FFF',
    width: '100%',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    [theme.breakpoints.down(768)] : {
      height : "55px"
    }
},
inputRootAS: { paddingLeft: '32px' },
searchBarAS: {
    color: 'rgba(33, 36, 38, 0.50)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
},
searchIconAS: {
    right: '12px',
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute' as 'absolute',
    // pointerEvents: 'none' as 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FE7F2D',
    textTransform: 'none' as 'none',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    borderRadius: '25px',
    width: '113px',
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: primaryColor,
      color: '#ffffff',
  },
  [theme.breakpoints.down(768)] : {
      width : '5px'
  }
},
filtersContainerAS: {
  width: "100%",
  maxWidth: "300px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  padding: '20px 0',
  borderRadius: "8px"
},
innerSlideBlock: {
    maxHeight: '234px',
    maxWidth: '280px',
    position: 'relative' as const
},
imageAS: {
    width: '100%',
    height: 'auto',
    borderRadius: '16px',
},
innerTripSlideBlockAS: {
  maxHeight: '226px',
  maxWidth: '300px',
  position: 'relative' as 'relative',
  [theme.breakpoints.up(768)]: {
      maxHeight: '213px',
  },
  [theme.breakpoints.up(1024)]: {
      maxHeight: '300px',
  },
  [theme.breakpoints.up(1440)]: {
      maxHeight: '378px',
  },
},
imageVignetteAS: {
  position: 'relative' as 'relative',
  cursor: 'pointer',
  '&:before': {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 'auto',
      background: 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))',
      pointerEvents: 'none',
      zIndex: 1,
      height: '40%',
      borderRadius: '0 0 16px 16px',
  }
},
fixedImgAS: {
  width: '300px !important',
  height: '226px !important'
},
agencyDetailsAS: {
  display: 'flex',
  alignItems: 'center',
  padding: '0 12px',
  gap: '8px',
  position: 'absolute' as const,
  bottom: 0,
  height: '49px',
  width: '100%',
  zIndex: 1
},
badgeAS: {
  '&.MuiAvatar-root': { width: '24px', height: '24px', }
},
font14AS: {
  fontSize: '14px',
},
serviceNameAS: {
  fontFamily: 'Inter',
  color: '#FFF',
  fontStyle: 'normal',
  lineHeight: '150%',
  fontWeight: 500,
  display: 'block',
  whiteSpace: 'nowrap' as const,
  textOverflow: 'ellipsis',
  maxWidth: '106.5px',
  overflow: 'hidden',
  textTransform: 'none' as const,
  fontSize: '14px',
  justifyContent: 'flex-start'
},
chipAS: {
  background: 'white',
  maxWidth: '58px',
  maxHeight: '27px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#F5950B',
  borderRadius: '17px'
},
chipLabelAS: {
  color: '#F5950B',
  textAlign: 'right' as const,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  letterSpacing: '0.301px',
  display: 'flex',
  alignItems: 'center',
  padding: '4px 16px'
},
ratingIconAS: {
  '& svg': {
      height: '16px',
      width: '16px',
  }
},
tripNameAS: {
  color: '#212426',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  letterSpacing: 0,
  marginTop: '16px',
  whiteSpace: 'nowrap' as const,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  cursor: 'pointer'
},
tripLocationAS: {
  color: 'rgba(33, 36, 38, 0.85)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: 0,
  marginTop: '8px',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical' as const,
  WebkitLineClamp: 1,
  overflow: 'hidden'
},
priceAS: {
  display: 'flex',
  gap: '12px',
  color: '#212426',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  alignItems: 'center',
  letterSpacing: 0,
  marginTop: '16px',
},
strikeThroughAS: {
  color: '#212426',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: 'normal',
  textDecorationLine: 'line-through',
},
paginationRootAS: {
  '& .MuiPaginationItem-root': {
    color: 'rgba(33, 36, 38, 0.50)',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    display: 'flex',
    width: '60.206px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    '&.Mui-selected': {
        color: '#FE7F2D',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
},
  '& li:first-child': {
      borderRadius: '8px',
      border: '1px solid rgba(33, 36, 38, 0.20)',
      padding: '4px 4.9px'
  },
  margin: 'auto',
  '& li:last-child': {
      borderRadius: '8px',
      border: '1px solid rgba(33, 36, 38, 0.20)',
      padding: '4px 4.9px'
  },
  
},
paginationItemAS: {
  "& .Mui-selected, & .Mui-selected:hover": {
      backgroundColor: "transparent", // Change this to the desired background color
      color: primaryColor, // Change this to the desired text color
  }
},
noBookingTextAS: {
  color: 'rgba(33, 36, 38, 0.90)',
  textAlign: 'center' as const,
  fontFamily: 'Inter',
  fontSize: '28px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '120%',
},
tripContainer : {
  [theme.breakpoints.down(1024)] :{
    display : 'grid',
    gap :'85px',

  }
},
OuterContainerTrip : {
  [theme.breakpoints.down(1024)]:{
    height : '1100px',
    overflowY : 'scroll'
  }
},
mainContainerTripAndFilter : {
  [theme.breakpoints.down(1024)]:{
     gap : '30px'
  }
},
breadcrSearchcont : {
  display : 'flex'  ,
  justifyContent : "space-between",
   alignItems :"center",
  [theme.breakpoints.down(768)]:{
    flexDirection : 'column' , 
    alignItems :"flex-start",
    gap : '20px'

 }
},
searchkeyWord : {
  [theme.breakpoints.down(768)] : {
    display : "none"
  }
},
menuIcon : {
  [theme.breakpoints.up(768)] : {
    display : "none",
  },
  cursor : 'pointer'

},
searchbreadCOnt : {
  [theme.breakpoints.down(768)] : {
    display : "flex",
    gap:'20px'
  }
},
dropdownContainer : {
  [theme.breakpoints.down(768)] : {
    display :"none"
  }
},
outrAdvncSrch : {
  margin: '32px 0',
  padding: '0 72px 0 61px',
 
   [theme.breakpoints.down(768)] : {
    padding: '0 40px 0 43px',
  }
},
filtercontainer : {
  [theme.breakpoints.down(768)] : {
  display : "none"
  }
},
filtercontainervisible : {
  [theme.breakpoints.down(768)] : {
     position : 'absolute',
     zIndex : '999'
    }
},
resetfiltercontainer : {
  display:'flex' ,
  gap : '10px',
  [theme.breakpoints.up(768)] : {
   display : 'none'
   }
},
resetfltrtext : {
  color: primaryColor,
  textDecoration: 'underline',
  cursor: 'pointer' ,
  whiteSpace:'nowrap' as const,
  [theme.breakpoints.down(768)] : {
    fontSize :'14px' ,
    marginTop : '2px'
    }
},
loader : {
  [theme.breakpoints.down(768)]:{
    marginLeft : '40px'
  }
},
filterInput : {
  marginLeft : '20px',
  [theme.breakpoints.up(768)]:{
    display : 'none'
  }
},
})

const AdvancedSearchWeb = withStyles(advancedSearchStyles)(AdvancedSearch)
export { AdvancedSearchWeb }

// Customizable Area End
