import React from "react";

// Customizable Area Start
import {
    Typography, Box, Divider, Tabs,
    Tab, TextField,
    Button, IconButton,Grid
} from "@material-ui/core";
import { Pagination } from '@material-ui/lab';
import {
    withStyles, Theme, ThemeProvider, createTheme
} from "@material-ui/core/styles";
import { DescriptionOutlined } from "@material-ui/icons";

import { searchIcon } from "./assets";
import { primaryColor } from "../../../components/src/theme";
import { bookingListTable } from "../../dashboard/src/Dashboard.web";
import ResponsiveBookingCard from "../../../components/src/ResponsiveBookingCard.web";
import { capitalizeFirstLetter } from "../../../components/src/utils";
import BookingDateSelect from "../../../components/src/BookingDateSelect.web";
import FilterSelect from "../../../components/src/FilterSelect";
const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        },
        MuiButtonBase: {
            root: {
                '&.MuiPaginationItem-root': {
                    color: 'rgba(33, 36, 38, 0.50)',
                    textAlign: 'center',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.1px',
                    display: 'flex',
                    width: '39.206px',
                    height: '32px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&.Mui-selected': {
                        color: '#FE7F2D',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }
                }
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});

const tabList = [
    { label: 'All', value: '' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Removed', value: 'closed' },
]

// Customizable Area End

import BookingListController, {
    Props
} from "./BookingListController.web";

export default class BookingsList extends BookingListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { 
            orders, orderPagination, searchValue, tripTitles, filteredTripTitle,
            dateRange, startDate, endDate, isSmallScreen
        } = this.state


        const uiFilters = () => {
            return (
                <Box
                    display={'flex'}
                    flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                    width={'100%'}
                    gridColumnGap={'5px'}
                    gridRowGap={'32px'}
                    marginBottom={isSmallScreen ? '32px' : '0px'}
                >
                    <Box
                    display={'flex'}
                    flexDirection={{ xs: 'row', sm: 'row', md: 'row', lg: 'row' }}
                    width={'100%'}
                    gridColumnGap={'5px'}
                    gridRowGap={'32px'}                  
                    >
                        <BookingDateSelect
                            fieldName="Date"
                            fieldValue={dateRange}
                            onChange={this.handleDateChange}
                            renderDateRange={this.renderDateRange}
                            handleApply={this.getOrdersDataRequest}
                            clearDate={()=> this.clearDate()}
                            startDate={startDate}
                            endDate={endDate}

                        />
                       
                        <FilterSelect
                            field="Trip"
                            options={tripTitles }
                            filterName="tripTitle"
                            handleFilterChange={this.handleSelectTitle }
                        />
                    </Box>
                    <TextField
                        id="search"
                        data-test-id="searchField"
                        value={searchValue}
                        placeholder="Search for something"
                        onChange={(event) => this.handleSearchTrip(event.target.value)}
                        inputProps={{
                            className: classes.searchInput
                        }}
                        InputProps={{
                            startAdornment: (
                                <img src={searchIcon} alt='search' style={{ width: '24px', height: '24px' }} />
                            ),
                            style: {
                                gap: '8px',
                                height: '48px',
                                padding: "15px 12px 14px",
                                display: 'flex',
                                width: '100%',
                                borderRadius: '8px',
                                border: `1px solid`,
                                alignItems: 'center',
                                borderColor: '#C3CAD9',
                                alignContent: 'flex-start',
                                backgroundColor: "#ffffff",
                                color: "rgba(33, 36, 38, 0.90)",
                            },
                            classes: { input: classes.input },
                            disableUnderline: true,
                        }}
                    />
                </Box>
            )
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box style={{padding: '0px 10px'}}>
                <Box className={classes.mainContainer}>
                    <Typography className={classes.headingTitleStyle}>
                        My Bookings
                    </Typography>
                    <Button
                            // style={{width: '180px'}}
                            className={`${classes.button} ${classes.secondaryButton}`}
                            startIcon={<DescriptionOutlined style={{color: primaryColor}} />}
                            onClick={this.navigateToReport}
                        >
                            Bookings Report
                        </Button>
                    <IconButton onClick={this.navigateToReport} className={classes.roundBtn} aria-label="delete">
                    <DescriptionOutlined style={{color: primaryColor}} />
                    </IconButton>
                </Box>
                {isSmallScreen && uiFilters()}
                <div style={{
                    backgroundColor: '#FFF',
                    borderRadius: '16px',
                    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.08)',
                    marginBottom: '16vh'
                }}>
                    <Box padding={isSmallScreen ? "10px" : "33px"}>
                            <Grid container className={classes.tabAndFiltersContainer} spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Tabs
                                        id="tabs"
                                        variant="scrollable"
                                        value={this.state.tab}
                                        TabIndicatorProps={{
                                            style: { backgroundColor: '#FE7F2D' },
                                        }}
                                        classes={{
                                            flexContainer: classes.flexContainer,
                                            root: classes.rootTabs,
                                        }}
                                        onChange={(event, value) => {
                                            this.handleChange(value);
                                        }}
                                    >
                                        {tabList.map(({ label, value }, index) => (
                                            <Tab
                                                key={index}
                                                label={label}
                                                value={value}
                                                classes={{
                                                    selected: classes.selectedTab,
                                                    wrapper: classes.tabItem,
                                                    textColorInherit: classes.textColor,
                                                    root: classes.rootTab,
                                                }}
                                            />
                                        ))}
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} className={classes.filterContainer}>
                                    {!isSmallScreen && uiFilters()}
                                </Grid>
                            </Grid>
                        {!isSmallScreen && bookingListTable(classes, orders, this.navigateBookingDetails, { letterSpacing: '0.1px', padding: '15px 16px 14px' })
                        }
                            {
                                isSmallScreen && orders?.length > 0 &&
                                orders.map((row: any) => (
                                    <ResponsiveBookingCard
                                    key={row.id}
                                name={row.attributes.customer_name}
                                image={row?.attributes?.trip_image}
                                tripTitle={row.attributes.trip_title}
                                amount={row.attributes.price}
                                date={row.attributes.trip_date}
                                to={capitalizeFirstLetter(row?.attributes?.to)}
                                bookingStatus={row.attributes.status}
                            />
                                ))
                            }
                    </Box>
                        {
                            orderPagination?.total_items > 10 && <> <Divider />
                                <Box position={'relative'}>
                                    <Box display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }} padding={{ xs: '16px', sm: '32px' }} alignItems="center" justifyContent="center">
                                        <Pagination
                                            data-testid="paginationBtn"
                                            count={orderPagination?.total_pages}
                                            page={this.state.page}
                                            onChange={this.handlePaginationChange}
                                            classes={{
                                                root: classes.paginationRoot,
                                                ul: classes.paginationUl, // Add this class for styling the unordered list
                                            }}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="center" padding={{ xs: '8px', sm: '0' }}>
                                        <Typography className={classes.paginationItems}>
                                            {orderPagination?.current_page}-{orderPagination?.total_pages} of {orderPagination?.total_items} items
                                        </Typography>
                                    </Box>
                                </Box>
                            </>
                        }
                            
                        
                
                </div>
                </Box>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = (theme: Theme) => ({
    mainContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '32px',
    },
    headingTitleStyle: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: 'normal',
        letterSpacing: '0.1px',
    },
    tabAndFiltersContainer: {
        display: 'flex', 
        flexWrap: 'wrap' as const,
        justifyContent: 'space-between',
        alignItems: 'center',
        // gap: '5px',
        marginBottom: '32px',
        [theme.breakpoints.down('md')]: {
            // gap: '32px'
        }
    },
    roundBtn: {
        display: 'none',
        pointerEvents: 'none' as const
    },
    outlineButton: {
        display: 'flex',
        width: '189px',
        padding: '12px 56px 12px 48px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '68px',
        border: '1px solid #FE7F2D',
    },
    buttonLabel: {
        color: primaryColor,
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        textAlign: 'center' as const,
        whiteSpace: 'nowrap' as const,
        textTransform: 'none' as const
    },
    selectedTab: {
        '& span': {
            color: '#212426',
            fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
        }
    },
    tabItem: {
        color: 'rgba(0, 0, 0, 0.40)',
        fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'none' as const
    },
    rootTab: {
        display: 'flex',
        padding: '4px 8px',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '4px',
        minWidth: 'auto',
        minHeight: 'auto'
    },
    rootTabs: {
        minHeight: '32px',
        height: '100%'
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        // gap: '10px',
    },
    inputBlack: {
        color: "#212426E5",
        fontFamily: "Inter",
        fontSize: '16px',
        fontWeight: 500
    },
    tripLabel: {
        height: '100%',
        color: '#92929D',
        lineHeight: 'normal',
        letterSpacing: '0.1px',
        marginRight: '12px',
        whiteSpace: 'nowrap' as const,
        display:'flex',
        alignItems:'center'
    },
    selectElement: {
        padding: '0 12px 0 0',
        cursor: 'pointer',
        width: '100%',
        userSelect: 'none' as const,
        borderRadius: 0,
        marginRight: '24px',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        textOverflow: 'ellipsis',
        height: '100%',
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: '0.1px',
        display:'flex',
        alignItems:'center'
    },
    searchInput: {
        '&::placeholder': {
            color: '#92929D',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
        }
    },
    input: {
        color: 'rgba(33, 36, 38, 0.90);',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: '0.1px',
    },
    
    paginationItems: {
        top: '10%',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        paddingLeft: '32px',
        letterSpacing: '0.1px',
        position: 'absolute' as const,
        color: 'rgba(33, 36, 38, 0.80)',
        [theme.breakpoints.down('sm')]:{
            top: '80%'
        }
    },
    paginationRoot: {
        margin: 'auto',
        '& li:first-child': {
            borderRadius: '8px',
            border: '1px solid rgba(33, 36, 38, 0.20)',
            padding: '4px 4.9px'
        },
        '& li:last-child': {
            borderRadius: '8px',
            border: '1px solid rgba(33, 36, 38, 0.20)',
            padding: '4px 4.9px'
        },
    },
    paginationUl: {
        '& li': {
            '@media (max-width: 599px)': {
                padding: '4px 2px', // Adjust the padding for smaller screens
            },
        },
    },
    menuRoot: {
        display: 'flex',
        padding: '8px 0px',
        flexDirection: 'column' as const,
        alignItems: 'flex-start',
        gap: '4px',
        alignSelf: 'stretch',
    },
    tableBody: {
        '& tr:first-child td': {
            paddingTop: '19px'
        }
    },
    noBookingText: {
        color: 'rgba(33, 36, 38, 0.90)',
        textAlign: 'center' as const,
        fontFamily: 'Inter',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '120%',
    },
    fitler_date: {
        maxWidth: "180px"
    },
    secondaryButton: {
        backgroundColor: "transparent",
        padding: '8px 24px',
        whiteSpace: 'nowrap' as const,
        borderRadius: "55px",
        textTransform: 'initial' as const,
        color: primaryColor,
        border: `1px solid ${primaryColor}`,
        width: '180px'
    },
    [theme.breakpoints.between(561, 1024)]:{
        headingTitleStyle:{
            fontSize: '24px'
        }
    },
    [theme.breakpoints.down(561)]: {
        mainContainer: {
            marginBottom: '24px',
            // padding: '10px'
        },
        headingTitleStyle:{
            fontSize: '18px'
        },
        roundBtn: {
            display: 'block',
            width: '40px',
            height: '40px',
            padding: '2px',
            minWidth: '0px !important',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            border: '1px solid #FE7F2D',
            pointerEvents: 'auto',
        },
        secondaryButton: {
            display: 'none',
            pointerEvents: 'none'
        }
    }
})

const BookingsListWeb = withStyles(styles)(BookingsList)
export { BookingsListWeb }
// Customizable Area End
