import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Divider,
    Button,
} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { primaryColor } from "./theme";
import { formatDate, useWindowWidth } from "./utils";

interface TripDateFilterProps {
    onChange: (name: string, value: Date | null) => void;
    renderDateRange: () => void;
    clearDate: () => void;
    startDate: Date | null;
    endDate: Date | null;
    handleApplyClick: () => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    filterDate: {
        maxWidth: "180px"
    },
    primaryButton: {
        width: '248px',
        padding: '12px 24px',
        whiteSpace: 'nowrap',
        borderRadius: "68px",
        textTransform: 'initial',
        backgroundColor: primaryColor,
        border: `1px solid ${primaryColor}`,
        color: "#ffffff",
        fontFamily: 'Inter',
        fontSize: '14px',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 12px',
            borderRadius: '34px',
        },
        "&:hover": {
            backgroundColor: primaryColor,
            color: '#ffffff'
        }
    },
    dateBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(1.25, 1.5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 1.5),
        },
    },
    dateText: {
        marginRight: theme.spacing(1),
        fontFamily: 'Inter',
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem',
        },
    },
    dateTextTo: {
        marginRight: theme.spacing(3.5),
        fontFamily: 'Inter',
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem',
        },
    },
    clearText: {
        marginRight: theme.spacing(2.5),
        color: 'red',
        cursor: 'pointer',
        fontFamily: 'Inter',
    },
    birthDateInput: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '4px',
        width: '100%',
    },
    birthDateSpan: {
        flex: 1,
        color: '#C3CAD9',
        fontSize: '14px',
    },
}));

const BookingDateFilter: React.FC<TripDateFilterProps> = ({
    onChange,
    startDate,
    endDate,
    renderDateRange,
    clearDate,
    handleApplyClick,
}) => {
    const classes = useStyles();
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;
    const isApplyBtnEnable = !!startDate && !!endDate;

    const applyDate = () => {
        renderDateRange();
        handleApplyClick();
    };

    const BirthDateInput = React.forwardRef(({ value, onClick, placeholder }: any, ref: any): React.ReactElement => {
        return (
            <div
                data-testid="singledate"
                onClick={onClick}
                ref={ref}
                className={classes.birthDateInput}
            >
                <span className={classes.birthDateSpan} style={{ color: value ? 'black' : undefined }}>
                    {!value ? placeholder : formatDate(value)}
                </span>
                <DateRangeIcon style={{ marginLeft: 'auto' }} />
            </div>
        );
    });

    return (
        <Box width="100%">
            <Box>
                <Box className={classes.dateBox}>
                    <Typography className={classes.dateText}>Date</Typography>
                    <Typography
                        onClick={() => clearDate()}
                        className={classes.clearText}
                    >
                        Clear
                    </Typography>
                </Box>
                <Divider />
                <Box className={classes.dateBox}>
                    <Typography className={classes.dateText}>From</Typography>
                    <DatePicker
                        className={classes.filterDate}
                        selected={startDate}
                        name="dateFrom"
                        onChange={(date) => onChange("dateFrom", date)}
                        dateFormat={'dd MMM yyyy'}
                        customInput={<BirthDateInput />}
                        showYearDropdown
                        showMonthDropdown
                    />
                </Box>
                <Box className={classes.dateBox}>
                    <Typography className={classes.dateTextTo} >To</Typography>
                    <DatePicker
                        selected={endDate}
                        name="dateTo"
                        onChange={(date) => onChange("dateTo", date)}
                        dateFormat={'dd MMM yyyy'}
                        customInput={<BirthDateInput />}
                        minDate={startDate}
                        showYearDropdown
                        showMonthDropdown
                    />
                </Box>
                <Box className={classes.dateBox}>
                    <Typography className={classes.dateText}>{""}</Typography>
                    <Button
                        className={classes.primaryButton}
                        onClick={applyDate}
                        disabled={!isApplyBtnEnable}
                    >
                        Apply
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default BookingDateFilter;
